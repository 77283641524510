import './Content.scss';
import React, { useRef } from 'react';
import useOnScreen from '../../Helpers/IsOnScreenHelper';
import Footer from '../Footer/Footer';


function Content() {


  const useRegisterRef = (id, ref) => {
    let visible = useOnScreen(ref);
    if (visible) {
      let elements = document.getElementsByClassName("sidebar-knob-holder");
      for (let element of elements) {
        element.classList.remove("active")

      }
      elements[id - 1].classList.add("active");


      elements = document.getElementsByClassName("headerTitle");
      for (let element of elements) {
        element.classList.remove("active")

      }
      elements[id - 1].classList.add("active");

    }
  }

  let ref1 = useRef()
  let ref2 = useRef()
  let ref3 = useRef()
  let ref4 = useRef()
  let ref5 = useRef()
  let ref6 = useRef()
  useRegisterRef(1, ref1);
  useRegisterRef(2, ref2);
  useRegisterRef(3, ref3);
  useRegisterRef(4, ref4);
  useRegisterRef(5, ref5);
  useRegisterRef(6, ref6);

  
  return (
    <div className="Content">
      <div ref={ref1} id="section-1" className="welcome">
        <div className="text-welcome-holder">
          <div className="text">
            <h1>
              <span>Welcome to my
                <img loading="lazy" title="angular" className="image-angular" alt="angular" src={require('../../Resources/Images/Fancy/angular.png')} />
                <img loading="lazy" title="csharp" className="image-csharp" alt="csharp" src={require('../../Resources/Images/Fancy/c-sharp.png')} />
              </span>
              <br></br>developer portfolio.</h1>
            <p>Let me present my story,
              <a className="lets-go" href="#section-2">let's go!</a>
            </p>
          </div>
        </div>
        <img loading="lazy" title="welcome to my portfolio" className="image-welcome" alt="programmer dude" src={require('../../Resources/Images/Fancy/coding-guy.png')} />
      </div>

      <div ref={ref2} id="section-2" className="main-skills">
        <p className="section-title">Main Skills</p>
        <div className="text-skills-holder">
          <span className="introduction-to-skills">
            <span>I have been developing Full-Stack Web-Applications, testing and working with specifications since {new Date().getFullYear() - 2014} years...</span><br></br>
            <span>Those are the skills i was able to aquire in that time, rated from one to five</span>
          </span>
          <div className="text-skills-holder-row">
            <article className="skills-backend">
              <div>Backend</div>
              <span className="skills-inner-row backend"><p>C#</p><span className="stars">⭐⭐⭐⭐⭐</span></span>
              <span className="skills-inner-row backend"><p>Entity Framework</p><span className="stars">⭐⭐⭐⭐</span></span>
              <span className="skills-inner-row backend"><p>.NET Core</p><span className="stars">⭐⭐⭐⭐</span></span>
              <span className="skills-inner-row backend"><p>MSSQL</p><span className="stars">⭐⭐⭐⭐</span></span>
              <span className="skills-inner-row backend"><p>MySQL</p><span className="stars">⭐⭐⭐</span></span>
            </article>

            <article className="skills-frontend">
              <div>Frontend</div>
              <span className="skills-inner-row frontend"><p>ASP .NET MVC</p><span className="stars">⭐⭐⭐⭐</span></span>
              <span className="skills-inner-row frontend"><p>Angular</p><span className="stars">⭐⭐⭐⭐</span></span>
              <span className="skills-inner-row frontend"><p>JavaScript</p><span className="stars">⭐⭐⭐⭐</span></span>
              <span className="skills-inner-row frontend"><p>HTML5</p><span className="stars">⭐⭐⭐</span></span>
              <span className="skills-inner-row frontend"><p>CSS3</p><span className="stars">⭐⭐⭐</span></span>
              <span className="skills-inner-row frontend"><p>React</p><span className="stars">⭐⭐</span></span>
              <span className="skills-inner-row frontend"><p>ASP.NET Web Forms</p><span className="stars">⭐⭐⭐</span></span>
            </article>


            <article className="skills-tools">
              <div>Tools & CI/CD</div>
              <span className="skills-inner-row tools"><p>Azure DevOps</p><span className="stars">⭐⭐⭐⭐⭐</span></span>
              <span className="skills-inner-row tools"><p>Azure (CI/CD)</p><span className="stars">⭐⭐⭐⭐</span></span>
              <span className="skills-inner-row tools"><p>Atlassian Jira</p><span className="stars">⭐⭐⭐</span></span>
              <span className="skills-inner-row tools"><p>Visual Studio</p><span className="stars">⭐⭐⭐⭐</span></span>
              <span className="skills-inner-row tools"><p>Visual Studio Code</p><span className="stars">⭐⭐⭐⭐⭐</span></span>
              <span className="skills-inner-row tools"><p>Mircosoft Windows / SQL Server</p><span className="stars">⭐⭐⭐⭐</span></span>
              <span className="skills-inner-row tools"><p>SQL Server Management Studio</p><span className="stars">⭐⭐⭐⭐</span></span>
              <span className="skills-inner-row tools"><p>GitLab</p><span className="stars">⭐⭐⭐</span></span>
            </article>

            <article className="skills-communication-and-more">
              <div>Communication</div>
              <span className="skills-inner-row communication"><p>German</p><span className="stars">⭐⭐⭐⭐⭐</span></span>
              <span className="skills-inner-row communication"><p>English</p><span className="stars">⭐⭐⭐⭐⭐</span></span>
              <span className="skills-inner-row communication"><p>French</p><span className="stars">⭐⭐⭐</span></span>
              <span className="skills-inner-row communication"><p>Swiss-German</p><span className="stars">⭐⭐⭐⭐⭐</span></span>
            </article>
          </div>

        </div>

        <img loading="lazy" title="programmer team" className="image-skills" alt="programmer team" src={require('../../Resources/Images/Fancy/coding-skills.png')} />
      </div>

      <div ref={ref3} id="section-3" className="jobs">
        <p className="section-title">Jobs</p>
        <div className="text-jobs-holder">
          <span className="introduction-to-jobs">
            <span>I have been developing applications in the C# environment for several years, in companies spanning from 50 employees up to multiple hundred employees</span><br></br>
            <span>
              Those are my past jobs i've worked in.</span>
          </span>
          <div className="text-jobs-holder-row">
          <article>
              <p className="job-title">Senior Software Engineer</p>
              <p className="job-company">Julius Baer Bank · Teilzeit</p>
              <p className="job-time">Nov. 2022 – Heute</p>
              <p className="job-where">Altstetten, Zürich, Schweiz</p>
              <p className="job-what">- Entwicklung von Webapplikationen</p>
              <p className="job-what">- C#, Angular, React, SQL, Typescript</p>
              <p className="job-what">- MSSQL</p>
              <p className="job-what">- SAFe</p>
              <p className="job-what">- Agile Entwicklung</p>
              <p className="job-what">- Jira Scrum</p>
              <p className="job-what">- Database Integration</p>
            </article>
            <article>
              <p className="job-title">Junior Software Engineer</p>
              <p className="job-company">Comitas AG · Teilzeit</p>
              <p className="job-time">Aug. 2019 – Oktober 2022 | 3 Jahre</p>
              <p className="job-where">Schlieren, Zürich, Schweiz</p>
              <p className="job-what">- Entwicklung von Webapplikationen</p>
              <p className="job-what">- C#, Angular, SQL, Typescript</p>
              <p className="job-what">- MSSQL / MySQL</p>
              <p className="job-what">- Agile Entwicklung</p>
              <p className="job-what">- Azure DevOps/Jira Scrum</p>
              <p className="job-what">- Azure DevOps CI/CD</p>
              <p className="job-what">- Database Integration</p>
              <p className="job-what">- Administration via SQL Server</p>
              <p className="job-what">- 3rd Level Support</p>
            </article>

            <article>
              <p className="job-title">Intern Software Engineer</p>
              <p className="job-company">Vistasys AG</p>
              <p className="job-time">Aug. 2018 - Aug. 2019 | 1 Jahr</p>
              <p className="job-where">Schafisheim, Kanton Aargau, Schweiz</p>
              <p className="job-what">- Entwicklung von Webapplikationen</p>
              <p className="job-what">- Entwicklung von Schnittstellen</p>
              <p className="job-what">- C#</p>
              <p className="job-what">- JavaScript</p>
              <p className="job-what">- React</p>
              <p className="job-what">- Node.js</p>
              <p className="job-what">- SQL</p>
              <p className="job-what">- CSS/SCSS</p>
              <p className="job-what">- Atlassian Jira, MSSQL</p>
              <p className="job-what">- 3rd Level Support</p>
            </article>
            <article>
              <p className="job-title">Apprentice Developer</p>
              <p className="job-company">Kanton Aargau - Lehrstelle</p>
              <p className="job-time">Aug. 2013 - Mär. 2016 | 2.75 Jahre</p>
              <p className="job-where">Aarau, Schweiz</p>
              <p className="job-what">- Anwendungsplanung</p>
              <p className="job-what">- Technische Dokumentation</p>
              <p className="job-what">- C#, .NET, SQL, CMD Scripts</p>
              <p className="job-what">- 1st und 3rd Level Support</p>
              <p className="job-what">- Testing</p>
            </article>
          </div>

        </div>

        <img loading="lazy" className="image-jobs" alt="programmer team" src={require('../../Resources/Images/Fancy/coding-team.png')} />
      </div>
      <div ref={ref4} id="section-4" className="education">
        <p className="section-title">Education</p>
        <div className="text-education-holder">
          <span className="introduction-to-education">
            <span>During my time as a student i've completed an apprenticeship, studied Computer Sciences @ FHNW and became a certified C# developer.</span><br></br>
            <span>Here are my educations & certficates (completed and ongoing)</span>
          </span>
          <div className="text-education-holder-row">
            <article>
              <p className="education-title stud">Informatik Student</p>
              <p className="education-company">Fachhochschule Nordwestschweiz FHNW</p>
              <p className="education-time">Sept. 2021 – Sept. 2025</p>
              <p className="education-where">Brugg, Aargau, Schweiz</p>
              <p className="education-what">BSc. Computer Sciences</p>
            </article>
            <article>
              <p className="education-title stud">Informatik Lernender</p>
              <p className="education-company">Berufsfachschule BBB Baden</p>
              <p className="education-time">2013 - 2018</p>
              <p className="education-where">Baden, Aargau, Schweiz</p>
              <p className="education-what">Applikationsentwickler EFZ</p>
            </article>
            <article>
              <p className="education-title stud">Schüler</p>
              <p className="education-company">Alte Kantonsschule Aarau</p>
              <p className="education-time">2016 - 2018</p>
              <p className="education-where">Aarau, Aargau, Schweiz</p>
              <p className="education-what">Kaufmännische Berufsmaturität</p>
            </article>
            <article>
              <p className="education-title certificate">C# Developer</p>
              <p className="education-company">Microsoft</p>
              <p className="education-time">Juni 2020</p>
              <p className="education-where">Completed via Online test</p>
              <p className="education-what">Exam 483: C# Development</p>
            </article>
            <article>
              <p className="education-title certificate">Advanced english speaker</p>
              <p className="education-company">Cambridge</p>
              <p className="education-time">2016</p>
              <p className="education-what">CAE - Cambridge Englisch Advanced</p>
            </article>
            <article>
              <p className="education-title certificate">Found french speaker</p>
              <p className="education-company">DELF</p>
              <p className="education-time">2014 / 2018</p>
              <p className="education-what">DELF B1 & B2</p>
            </article>


          </div>

        </div>

        <img loading="lazy" className="image-education" alt="education" src={require('../../Resources/Images/Fancy/education.png')} />
      </div>
      <div ref={ref5} id="section-5" className="projects">
        <p className="section-title">Projects</p>
        <div className="text-projects-holder">
          <span className="introduction-to-projects">
            <span>Here's a broad idea on what types of projects i've been working on!
              <br></br> Technologies used include but not exclusively 
            </span>
              <br></br>
          </span>
          <div className='tags'>
                <span>Angular</span>
                <span>TypeScript</span>
                <span>React</span>
                <span>C#</span>
                <span>EF</span>
                <span>MSSQL</span>
              </div>
          <div className="text-projects-holder-row">
            
          <article className="projects">
              <img 
                loading="lazy" 
                title="Advisory Mandates Platform" 
                alt="Advisory Mandates Platform" 
                src={require('../../Resources/Images/Projects/advisorymandate.jpg')} />
              <h3>Advisory Mandates Platform</h3>
              <article>
                <h4>Advisory Mandates Platform</h4>
                Description
              </article>
            </article>

            <article className="projects">
              <img 
                loading="lazy" 
                title="Discretionary Mandates Platform" 
                alt="Discretionary Mandates Platform" 
                src={require('../../Resources/Images/Projects/discretionarymandate.jpg')} />
              <h3>Discretionary Mandates Platform</h3>
              <article>
                <h4>Discretionary Mandates Platform</h4>
                Description
              </article>

            </article>

            
          <article className="projects">
              <img 
                loading="lazy" 
                title="Financial Instruments Data" 
                alt="Financial Instruments Data" 
                src={require('../../Resources/Images/Projects/financialinstruments.jpg')} />
              <h3>Financial Instruments Data</h3>
              <article>
                <h4>Financial Instruments Data</h4>
                Description
              </article>

            </article>

            
          <article className="projects">
              <img 
                loading="lazy" 
                title="Tech Talent Recruiting Platform" 
                alt="Tech Talent Recruiting Platform" 
                src={require('../../Resources/Images/Projects/techtalentrecruitingplatform.jpg')} />
              <h3>Tech Talent Recruiting Platform</h3>
              <article>
                <h4>Tech Talent Recruiting Platform</h4>
                Description
              </article>

            </article>

            
            <article className="projects">
              <img 
                loading="lazy" 
                title="Legal Protection Administration" 
                alt="Legal Protection Administration" 
                src={require('../../Resources/Images/Projects/legalprotectionadministration.jpg')} />
              <h3>Legal Protection Administration</h3>

              <article>
                <h4>Legal Protection Administration</h4>
                Description
              </article>

            </article>

            <article className="projects">
              <img 
                loading="lazy" 
                title="CRM / Accounting Platform" 
                alt="CRM / Accounting Platform"
                src={require('../../Resources/Images/Projects/crmaccountingplatform.jpg')} />
              <h3>CRM / Accounting Platform</h3>

              <article>
                <h4>CRM / Accounting Platform</h4>
                Description
              </article>
            </article>
            <article className="projects">
              <img 
                loading="lazy" 
                title="Printer Logistics" 
                alt="Printer Logistics" 
                src={require('../../Resources/Images/Projects/printerlogistics.jpg')} />
              <h3>Printer Logistics</h3>

              <article>
                <h4>Printer Logistics</h4>
                Description
              </article>

            </article>
          {/* <article className="projects">
              <img 
                loading="lazy" 
                title="Owners Meeting" 
                alt="Owners Meeting" 
                src={require('../../Resources/Images/Projects/ownersmeeting.jpg')} />
              <h3>Owners Meeting</h3>

              <article>
                <h4>Owners Meeting</h4>
                Description
              </article>

            </article> */}
            <article className="projects">
              <img 
                loading="lazy" 
                title="Automobile Logistics Tracking" 
                alt="Automobile Logistics Tracking" 
                src={require('../../Resources/Images/Projects/carlogisticstracking.jpg')} />
              <h3>Automobile Logistics Tracking</h3>

              <article>
                <h4>Automobile Logistics Tracking</h4>
                Description
              </article>

            </article>

            <article className="projects">
              <img 
                loading="lazy" 
                title="Car Fleet Management" 
                alt="Car Fleet Management" 
                src={require('../../Resources/Images/Projects/carfleetmanagement.jpg')} />
              <h3>Car Fleet Management</h3>

              <article>
                <h4>Car Fleet Management</h4>
                Description
              </article>

            </article>
            <article className="projects">
              <img 
              loading="lazy" 
              title="Car Order Tracking" 
              alt="Car Order Tracking" 
              src={require('../../Resources/Images/Projects/carordertracking.jpg')} />
              <h3>Car Order Tracking</h3>

              <article>
                <h4>Car Order Tracking</h4>
                Description
              </article>

            </article>
            <article className="projects">
              <img 
                loading="lazy" 
                title="Civil Engineering Administration" 
                alt="Civil Engineering Administration" 
                src={require('../../Resources/Images/Projects/civilengineeringadministration.jpg')} />
              <h3>Civil Engineering Administration</h3>

              <article>
                <h4>Civil Engineering Administration</h4>
                Description
              </article>

            </article>
            <article className="projects">
              <img 
                loading="lazy" 
                title="Ticket Membership Platform" 
                alt="Ticket Membership Platform" 
                src={require('../../Resources/Images/Projects/ticketmembershipplatform.jpg')} />
              <h3>Ticket Membership Platform</h3>
              <article>
                <h4>Ticket Membership Platform</h4>
                Description
              </article>

            </article>

          </div>

        </div>

      </div>
      <div ref={ref6} id="section-6" className="about-me">
        <p className="section-title">About me</p>

        <span className="about-me-text-holder">

          <span>I am Marco and i'm a C# and Angular software engineer, i mainly focus on building modern web applications for medium to large businesses</span><br></br>

          <span>Being a developer since about {new Date().getFullYear() - 2014} years, i am still learning day by day, but i am focusing on C#, Angular (TypeScript) and Entity Framework</span><br></br>

          <span>As a software engineer, i really like the communication inside the team and trying to get the best out of each project.
            I really like that our job does not focus on a single area of expertise, but is rather diverse with topics like distribution, financials, insurance, automotives etc..
            That is essentially what makes me love the job over time.</span><br></br>

          <span>In 2021 i began studying @ FHNW in Brugg to reach one of my next milestones, the BSc. in Computer Sciences</span><br></br>

          <span>To balance my life, i try to often meet friends and go to the gym around every other day, this keeps me motivated throughout the week.
                But i do also love to travel, eat good food or do spontaneous trips. </span>

        </span>

        <img loading="lazy" title="about me" className="image-aboutme" alt="aboutme" src={require('../../Resources/Images/Fancy/about-me.png')} />

      </div>
      <Footer />
    </div>
  );
}

export default Content;
