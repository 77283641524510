import './Sidebar.scss';
import SidebarKnob from './SideBarKnob';
import React from 'react';

const sidebarClass = "Sidebar";

class Sidebar extends React.Component {


  render() {
    return (
      <div className={sidebarClass}>

        {[1, 2, 3, 4, 5, 6].map(x => {
          return <SidebarKnob key={x} id={x}></SidebarKnob>
        })}

      </div>
    );
  }
}

export default Sidebar;
