import Header from '../Header/Header';
import Sidebar from '../Sidebar/Sidebar';
import Content from '../Content/Content';
import './Root.scss';

function Root() {
  return (
    
    <div className="Root">
      <Header />
      <Content />
      <Sidebar />
    </div>
  );
}

export default Root;
