import './Sidebar.scss';

const sideBarKnobHolderClassAndActive = "sidebar-knob-holder active";
const sideBarKnobHolderClass = "sidebar-knob-holder";
const sideBarKnobClass = "sidebar-knob";

const SidebarKnob = props => {
    return (
        <a id={"knob-section-" +props.id} key={props.id} href={"#section-" +props.id} className={props.id === 1 ? sideBarKnobHolderClassAndActive : sideBarKnobHolderClass} >
            <span className={sideBarKnobClass}></span>
        </a >
    );
}

export default SidebarKnob;
