import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import './Footer.scss';

const Footer = () => {
  return (
    <footer className="Footer">
      <div className="Footer-content">
        <a href="https://github.com/marcoruch" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faGithub}  size='x'/>
        </a>
        <a href="https://www.linkedin.com/in/marco-ruch/" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faLinkedin} size='x' />
        </a>
      </div>
    </footer>
  );
}

export default Footer;