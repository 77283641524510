import './Header.scss';

function Header() {
    const headers = []

    const addHeader = (id, title) => {
        headers.push({id, title});
    }

    addHeader(1, "Welcome");
    addHeader(2, "Skills");
    addHeader(3, "Jobs");
    addHeader(4, "Education");
    addHeader(5, "Projects");
    addHeader(6, "About me");

    return (
        <div className="Header">
            <div className='portfolio-title' href="marcoruch.ch">
                <div className='vs-code-logo'></div>
                <span className='first'>Marco</span>
                <span className='second'>Ruch</span>
            </div>
            {headers.map(x => {
                return  <a id={"headerTitle-" + x.id} key={x.title} className={"title-" + x.id +  " headerTitle " + (x.id == 1 ? "active" : "")} href={"#section-" + x.id} >{x.title}</a>
            })}
        </div>
    );
}

export default Header;
